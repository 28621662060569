import { useState, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../state/store";
import { StringInput } from "../../components/Inputs";
import { ChapterSelector, SettingsSelector } from "../../components/settings";
import { InfoBox } from "../../components/InfoBox";
import { PlusMinusButton } from "../../components/PlusMinusButton";
import { ESVText } from "../../components/ESVText";
import { TournamentLink } from "../../components/TournamentComponents/TournamentLink";
import { chapterSelected } from "../../util/generalUtil";
import { SET_QUIZ_NOW_QUIZZERS } from "../../state/reducers/settings";
import { updateUser } from "../../state/actions/authentication";
import "./MainMenu.scss";

export function MainMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const settings = useSelector((state: RootStore) => state.settings);
  const { selectedSeason, selectedEvent } = useSelector(
    (state: RootStore) => state.sk
  );
  const appDetails = useSelector((state: RootStore) => state.appDetails);
  const { user } = useSelector((state: RootStore) => state.authentication);

  const isChapterSelected = chapterSelected(settings.selectedChapters);
  const view: string = location?.state?.menuView || "Menu";
  const setView = (view: string) => {
    navigate(location.pathname, { state: { menuView: view } });
  };

  const [quizNowQuizzers, setQuizNowQuizzers] = useState<string[]>([]);
  const startEnabled = useMemo(() => {
    let newEnabled = false;
    quizNowQuizzers.forEach((quizzer) => {
      if (quizzer) newEnabled = true;
    });
    return newEnabled;
  }, [quizNowQuizzers]);

  let mainContent = null;
  switch (view) {
    case "Chapters":
      mainContent = <ChapterSelector onClickBack={() => setView("Menu")} />;
      break;
    case "Settings":
      mainContent = (
        <SettingsSelector
          onClickBack={(newSettings) => {
            setView("Menu");
            if (user) {
              dispatch(
                updateUser({
                  ...user,
                  preferences: {
                    ...user.preferences,
                    settings: {
                      ...newSettings,
                      // @ts-ignore
                      selectedChapters: undefined,
                      touchscreen: undefined,
                    },
                  },
                })
              );
            }
          }}
        />
      );
      break;
    case "QuizNow":
      mainContent = (
        <div>
          <h1
            style={{
              margin: 0,
              fontSize: 24,
              marginBottom: 20,
            }}
          >
            Quiz Now
          </h1>
          {quizNowQuizzers.map((item, index) => (
            <StringInput
              key={`quiz-now-quizzer-${index}`}
              caption={`Quizzer ${index + 1}:`}
              value={item}
              onChange={(value) => {
                const result = [...quizNowQuizzers];
                result[index] = value;
                setQuizNowQuizzers(result);
              }}
              afterInput={
                <>
                  {(index > 0 || quizNowQuizzers.length > 1) && (
                    <div style={{ marginLeft: 5 }}>
                      <PlusMinusButton
                        onClick={() => {
                          const newList = [...quizNowQuizzers];
                          newList.splice(index, 1);
                          setQuizNowQuizzers(newList);
                        }}
                      >
                        -
                      </PlusMinusButton>
                    </div>
                  )}
                  {quizNowQuizzers.length === index + 1 && (
                    <div style={{ marginLeft: 5 }}>
                      <PlusMinusButton
                        onClick={() => {
                          const newList = [...quizNowQuizzers];
                          newList.push("");
                          setQuizNowQuizzers(newList);
                        }}
                      >
                        +
                      </PlusMinusButton>
                    </div>
                  )}
                </>
              }
            />
          ))}
          <div style={{ display: "flex", marginTop: 15, marginBottom: 15 }}>
            <button
              className="back-button"
              onClick={() => setView("Menu")}
              style={{ marginRight: 15 }}
            >
              Go Back
            </button>
            <button
              className={startEnabled ? "clickable" : "disabled-clickable"}
              onClick={() => {
                if (!startEnabled) return;
                dispatch({
                  type: SET_QUIZ_NOW_QUIZZERS,
                  payload: quizNowQuizzers,
                });
                navigate("/quiznow");
                window.scrollTo(0, 0);
              }}
            >
              Start
            </button>
          </div>
        </div>
      );
      break;
    case "Contact":
      mainContent = (
        <InfoBox onGoBack={() => setView("Menu")}>
          <>
            <p>
              <span>{"Copyright © 2025 "}</span>
              <TournamentLink pageId="Peter Gossell" />
              <span>{`.${
                appDetails.version ? ` Version ${appDetails.version}` : ""
              }`}</span>
            </p>
            <p>
              Email:{" "}
              <a href="mailto:peter@biblequizacademy.com">
                peter@biblequizacademy.com
              </a>
            </p>
            <p>
              Twitter:{" "}
              <a href="https://www.twitter.com/bqacademy">@bqAcademy</a>
            </p>
            <p>
              2016 version of the English Standard Version (ESV) ©{" "}
              <a href="https://www.crossway.org">Crossway</a>.{" "}
              <label className="link" onClick={() => setView("ESV")}>
                Learn More
              </label>
            </p>
            <p>
              For more information about Bible Quizzing and BQF, see{" "}
              <a href="http://www.summitbiblequizzing.org">
                Summit Bible Quizzing
              </a>{" "}
              and{" "}
              <a href="http://www.biblequizfellowship.org">
                Bible Quiz Fellowship
              </a>
              .
            </p>
          </>
        </InfoBox>
      );
      break;
    case "Contribute":
      mainContent = (
        <InfoBox onGoBack={() => setView("Menu")}>
          <>
            <h3>Code with us!</h3>
            <p>
              If you want to help program new features to BQA, or are even
              remotely interested in it, please do get in touch. We use{" "}
              <a href="https://reactjs.org/">ReactJS</a>,{" "}
              <a href="https://expressjs.com/">ExpressJS</a>,{" "}
              <a href="https://www.typescriptlang.org/">TypeScript</a>, and{" "}
              <a href="https://git-scm.com/">Git</a>, though you do not need
              experience in those technologies to get involved. All I ask is
              that you are enthusiastic about Bible Quizzing and have some
              programming experience.&nbsp;
              <button
                className="link"
                style={{ padding: 0 }}
                onClick={() => setView("Contact")}
              >
                Email me
              </button>{" "}
              for details. Perks include being a part of one of the most
              up-to-date pieces of quizzing software, a great item for your
              résumé, and even your own biblequizacademy.com email address!
            </p>
            <h3>Tournaments</h3>
            <p>
              Our new <Link to="/tournaments">Tournament Tracker</Link> needs a
              lot of data from a lot of different sources, including rosters,
              stats, quizzer bios, and more. If you are interested in keeping
              tournaments from your ministry up-to-date, or filling in data from
              past tournaments,{" "}
              <button
                className="link"
                style={{ padding: 0 }}
                onClick={() => setView("Contact")}
              >
                let's get in touch
              </button>
              .
            </p>
            <h3>Beta Testers</h3>
            <p>
              I also need volunteers to beta test new features and give feedback
              to me, or whoever else is working on them. Again,&nbsp;
              <button
                className="link"
                style={{ padding: 0 }}
                onClick={() => setView("Contact")}
              >
                Email me
              </button>{" "}
              for details. Perks include being the first to see new features and
              being able to provide direct feedback which will influence how BQA
              looks in the future.
            </p>
            <p style={{ marginTop: 10 }}>
              If you would like to contribute financially to the advancement of
              Bible Quizzing and Bible Quiz Academy, contribute instead to your
              local quizzers who would otherwise be unable or unwilling to go to
              Nationals.
            </p>
          </>
        </InfoBox>
      );
      break;
    case "Help":
      mainContent = (
        <InfoBox onGoBack={() => setView("Menu")}>
          <>
            <p>
              <b>To get started</b>, visit the Chapters menu and select your
              material. You can quiz over any material in the New Testament.
              Check out the Settings menu to see what options are available to
              you for the various quiz modes.
            </p>
            <p>
              <b>Quiz Now</b>&nbsp; is just like individual quizzing, just on
              your device. Type in the names of all quizzers, click Start, and
              you will be in a quiz. In the Settings menu, you can decide how
              you want to jump: using buttons on the screen (best for mobile
              devices) or with keys on the keyboard (best for non-touchscreen
              devices). Once in the game, click Ask Question, and words will
              appear on the screen. Jump when you're ready, and you'll be all
              set!
            </p>
            <p>
              <b>Verse Cards</b>&nbsp; allows you to review your verses in order
              or randomized. Just click Next Verse and quote your verse. You can
              also use Speech to Text, where you say the verse out loud and your
              microphone will put your words on the screen. You still make the
              final judgement of whether you got it right or wrong, but you can
              use the computer to help you out.
            </p>
            <p>
              <b>Multiplayer Quizzing</b> allows you to quiz with other quizzers
              on different devices. No need for Zoom, no worry about lag - you
              don't even need a quizmaster - everything is handled by BQA
              Online! Microphone access will be required. See for yourself by
              clicking Multiplayer from the main menu. In multiplayer Quizzing,
              you can quiz individually or <b>on teams</b>.
            </p>
            <p>
              <b>Scorekeeper</b> in BQA allows coaches to easily keep score for
              their team. See{" "}
              <button
                className="link"
                onClick={() => navigate("/sk/help")}
                style={{ fontSize: 16, marginLeft: -4 }}
              >
                Scorekeeper Help
              </button>
              for more information.
            </p>
          </>
        </InfoBox>
      );
      break;
    case "ESV":
      mainContent = (
        <InfoBox onGoBack={() => setView("Menu")}>
          <>
            <ESVText />
            <p>
              See{" "}
              <a
                href="https://www.esv.org/resources/esv-global-study-bible/copyright-page/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ESV's Website
              </a>{" "}
              for information on how you can freely use their text.
            </p>
          </>
        </InfoBox>
      );
      break;
    default:
      mainContent = (
        <>
          <div
            style={{
              marginTop: 40,
              textAlign: window.innerWidth < 900 ? "center" : undefined,
              marginBottom: 60,
            }}
          >
            {!isChapterSelected && (
              <div
                className="important-information"
                style={{ marginBottom: 5 }}
              >
                Quizzers: Please select a chapter
              </div>
            )}
            <button
              className={isChapterSelected ? "clickable" : "disabled-clickable"}
              onClick={() => {
                setQuizNowQuizzers([settings.quizNowQuizzers[0]]);
                setView("QuizNow");
              }}
              disabled={!isChapterSelected}
            >
              Quiz Now
            </button>
            <br />
            <br />
            <button
              className={isChapterSelected ? "clickable" : "disabled-clickable"}
              onClick={() => {
                navigate("/versecards");
                window.scrollTo(0, 0);
              }}
              disabled={!isChapterSelected}
            >
              Verse Cards
            </button>
            <br />
            <br />
            <Link className="clickable" to="/multiplayer">
              Multiplayer
            </Link>
            <br />
            <br />
            <Link
              className="clickable"
              to={
                selectedSeason
                  ? `/sk/${selectedSeason.seasonId}/${
                      selectedEvent || "events"
                    }`
                  : "/sk/seasons"
              }
            >
              Scorekeeper
            </Link>
            <br />
            <br />
            <Link className="clickable" to="/tournaments">
              Tournaments
            </Link>
            <br />
            <br />
            <button className="clickable" onClick={() => setView("Chapters")}>
              Chapters
            </button>
            <br />
            <br />
            <button className="clickable" onClick={() => setView("Settings")}>
              Settings
            </button>
          </div>
          <div className="menu-footer">
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: 15,
                paddingTop: 14,
              }}
            >
              <button
                className="link"
                style={{ marginRight: 8 }}
                onClick={() => setView("ESV")}
              >
                ESV
              </button>
              <button
                className="link"
                style={{ marginRight: 8 }}
                onClick={() => setView("Help")}
              >
                Help
              </button>
              <button
                className="link"
                style={{ marginRight: 8 }}
                onClick={() => setView("Contact")}
              >
                About
              </button>
              <button
                className="link"
                style={{ marginRight: 8 }}
                onClick={() => setView("Contribute")}
              >
                Get Involved
              </button>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              top: -30,
            }}
          >
            {user?.securityClearance?.logonType === "ADMIN" && (
              <a href="/admin" style={{ marginRight: 10 }}>
                Admin
              </a>
            )}
            {user ? (
              <Link
                className="link"
                to="/user"
                style={{ marginRight: 10 }}
              >{`Hi, ${user.userName}`}</Link>
            ) : (
              <button
                style={{ marginRight: 10 }}
                onClick={() => navigate("/login")}
                className="link"
              >
                Login
              </button>
            )}
          </div>
        </>
      );
  }
  return (
    <div className="page" style={{ minWidth: "unset", padding: "unset" }}>
      <div
        style={{
          textAlign: "center",
          paddingTop: 15,
          fontSize: "min(56px, max(5.5vw, 24px))",
        }}
      >
        <span>Bible Quiz Academy</span>
        <span style={{ fontSize: 16 }}>.com</span>
      </div>
      <hr
        style={{
          backgroundColor: "grey",
          height: 2,
          marginTop: 15,
          border: "0",
          marginLeft: -8,
        }}
      />
      <div
        style={{
          marginTop: 0,
          marginBottom: 30,
          position: "relative",
          padding: "0px 20px",
        }}
      >
        {mainContent}
      </div>
    </div>
  );
}
